<h1 class="entry-title">Conservatories</h1>
<div class="entry-content">
  <p>
    Do you need extra space? Why not individualise your home by adding a
    conservatory as a key feature to your property. Homeowners can choose a
    double glazed PVCu conservatory to enjoy all year round to serve their
    needs. A conservatory is a practical and feasible extension to your home,
    which gives you extra space, whilst adding value to your property. The
    extra space can be converted into a play room for the children, a dining
    room, an office or study, or an extra room to relax in.
  </p>
  <p>
    Whatever your preferences we are sure that we can assist you to find a
    conservatory that you desire. You can choose a conservatory from garden
    rooms to Edwardian and Victorian designs to reflect and suit your home.
  </p>
  <p>
    So contact Royale Glazing Ltd for a no obligation quotation
    to discuss the most effective conservatory design, which can be enjoyed by
    you and your family throughout the seasons.
  </p>
  <a routerLink="/Galleries/Conservatories">
    <img src="assets/images/conserv.png" alt="" class="linkImage">
  </a>
</div>

