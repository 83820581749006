import { Router } from '@angular/router';
import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  navigations = [
    {
      NavigateTo: "/",
      Title: "Home"
    },
    {
      NavigateTo: "/Windows",
      Title: "Windows"
    },
    {
      NavigateTo: "/DoorsPatios",
      Title: "Doors / Patios"
    },
    {
      NavigateTo: "/Conservatories",
      Title: "Conservatories"
    },
    {
      Title: "Galleries",
      SubPages: [
        {
          NavigateTo: "/Galleries/Conservatories",
          Title: "Conservatories gallery"
        },
        {
          NavigateTo: "/Galleries/Doors",
          Title: "Doors gallery"
        },
        {
          NavigateTo: "/Galleries/FrostedGlass",
          Title: "Frosted glass gallery"
        },
        {
          NavigateTo: "/Galleries/Windows",
          Title: "Windows gallery"
        }
      ]
    },
    {
      NavigateTo: "/Testimonials",
      Title: "Testimonials"
    },
    {
      NavigateTo: "/ContactUs",
      Title: "Contact us"
    }
  ];

  navigationsMobile = [
    {
      NavigateTo: "/Windows",
      Title: "Windows"
    },
    {
      NavigateTo: "/DoorsPatios",
      Title: "Doors / Patios"
    },
    {
      NavigateTo: "/Conservatories",
      Title: "Conservatories"
    }
  ];


  title = 'Royale-Glazing';

  @ViewChild('snav') sidenav: MatSidenav;
  public subscription: Subscription;
  public status: boolean = false;

  constructor(
    private router: Router,
  ) {}

  ngAfterViewInit(): void {
    this.subscription = this.sidenav.closedStart.subscribe(() => this.status = false);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  isLinkActive(nav): boolean {
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url :
    this.router.url.slice(0, queryParamsIndex);
    return (nav.SubPages && nav.SubPages.map(p => p.NavigateTo === baseUrl).filter(p => p).length > 0) || baseUrl === nav.NavigateTo;
  }

  isHome(): boolean{
    const queryParamsIndex = this.router.url.indexOf('?');
    const baseUrl = queryParamsIndex === -1 ? this.router.url : this.router.url.slice(0, queryParamsIndex);
    return baseUrl === "/";
  }
}
