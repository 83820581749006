<div class="mainContainer">
  <mat-sidenav-container [hasBackdrop]="true">
    <mat-sidenav #snav [mode]="'over'" position="end">
      <div class="navContainer">
        <button class="menuButton" mat-icon-button (click)="snav.toggle()"><mat-icon>close</mat-icon></button>
        <div class="navButtons">
          <div *ngFor="let nav of navigations;" class="navButton">
            <a *ngIf="!nav.SubPages" routerLink="{{nav.NavigateTo}}" (click)="snav.toggle()" [ngClass]="{'active': isLinkActive(nav)}" >
              {{nav.Title}}
            </a>
            <a *ngIf="nav.SubPages" style="cursor: pointer;" [ngClass]="{'active': isLinkActive(nav)}" (click)="status = !status">
              {{nav.Title}}
            </a>
            <div class="subPages" *ngIf="nav.SubPages" (click)="snav.toggle()" [ngClass]="{'open': status}">
              <a *ngFor="let sub of nav.SubPages;" routerLink="{{sub.NavigateTo}}">
                {{sub.Title}}
              </a>
            </div>
          </div>
        </div>
      </div>
    </mat-sidenav>

    <mat-sidenav-content>
      <header>
        <div class="logoBar">
          <div class="preLogo"></div>
          <div class="centerContainer">
            <div class="logo">
              <img src="assets/images/rglazing.png" alt="" routerLink="/" style="cursor: pointer;">
              <div class="call">
                <span style="font-size: 1.8rem">Call us now on </span>
                <span style="font-size: 3.6rem"><a class="notlink white phone" href="tel:0208 890 1600">0208 890 1600</a></span>
              </div>
            </div>
          </div>
        </div>

        <div class="navBar">
          <div class="menuContainer centerContainer">
            <div *ngFor="let nav of navigationsMobile;" class="navButton">
              <a routerLink="{{nav.NavigateTo}}" [ngClass]="{'active': isLinkActive(nav)}">
                {{nav.Title}}
              </a>
            </div>
            <div style="display: flex; align-items: center; justify-content: center;">
              <button class="menuButton" mat-icon-button (click)="snav.toggle()"><mat-icon>menu</mat-icon></button>
            </div>
          </div>
          <div class="navButtons centerContainer">
            <div *ngFor="let nav of navigations;" class="navButton" (click)="status = !status">
              <a *ngIf="!nav.SubPages" routerLink="{{nav.NavigateTo}}" [ngClass]="{'active': isLinkActive(nav)}">
                {{nav.Title}}
              </a>
              <a *ngIf="nav.SubPages" [ngClass]="{'active': isLinkActive(nav)}" >
                {{nav.Title}}
              </a>
              <div #subPages class="subPages" *ngIf="nav.SubPages">
                <a *ngFor="let sub of nav.SubPages;" routerLink="{{sub.NavigateTo}}">
                  {{sub.Title}}
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div class="centerContainer">
        <img *ngIf="isHome()" src="assets/images/wdc.png" alt="" style="height: auto; width: 100%; margin: 10px 0">
      </div>
      <div class="routerWrap centerContainer">
        <router-outlet></router-outlet>
      </div>

      <footer>
        <div class="centerContainer">
          Copyright © 2022 - Royale Glazing Limited
        </div>
      </footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
