<h1 class="entry-title">Testimonials</h1>
<div class="entry-content">
  <p><strong>Double door and single door – Hounslow</strong><br>
  “Over a number of years Royale Glazing have replaced all the doors and windows in my house and that of my daughter. I would recommend them to anyone – Their work is of a consistently high standard in all areas. I have never had any problems with their work and would use their services again.”</p>
  <p><strong>Folding doors – Shepperton</strong><br>
  “They are very nice – Time keeping and honest. Hard working, work done is very nice.”</p>
  <p><strong>Windows – Feltham</strong><br>
  “Excellent workers! Polite, tidy and helpful, will have them again! Would recommend to everybody! Thank you!”</p>
  <p><strong>Windows and doors – Twickenham</strong><br>
  “I am delighted with the quality of the windows and upvc door Royale Glazing fitted. The fitting team were meticulous in their reinstatement of the brickwork and re-plastering. I would strongly recommend them.”</p>
  <p><strong>Windows and doors – Loughton</strong><br>
  “I am so impressed with the quality of the windows and the excellent finish that has been achieved. 10/10 Brilliant.”<br>
  8 Doors – Woking&nbsp;“Very impressed with the standard of work carried out. Polite and tidy gentleman too.”</p>
  <p><strong>Windows – Twickenham</strong><br>
  “Very good workmanship, splendid service, polite honest and reliable. Would highly recommend.”</p>
  <p><strong>Window and door – Sunbury</strong><br>
  “It was very hard to find a company to fit our doors until we found Royale Glazing. Very good reliable company fitters were spot on marvellous. I would highly recommend them.”</p>
</div>
