<h1 class="entry-title">Doors / Patios</h1>
<div class="entry-content">
  <p>
    Select from a wide range of designs and styles that suit your home. When
    choosing new doors one should take into consideration the different types
    of doors and the uses to which the doors are put.
  </p>
  <p>
    You can put your mind at ease, by allowing us to guide you through all the
    available types of doors, namely; front doors, french doors, patio doors,
    back doors, composite doors and decorative doors.
  </p>
  <p>
    All our PVCu doors have been designed to give the optimum levels of
    performance and appearance. The profile of all our doors takes into
    account high quality security locking systems, as well as longer lasting
    performance. We ensure that every door is tailor made to personal
    preference and specification, using a wide range of glass designs, colours
    and finishes.
  </p>
  <a routerLink="/Galleries/Doors">
    <img src="assets/images/doors.png" alt="" class="linkImage">
  </a>
</div>
