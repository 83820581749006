<h1 class="entry-title">Contact Us</h1>
<div class="entry-content">
  <p>
    <strong>Royale Glazing Ltd</strong><br>
    Unit 1,&nbsp;Feltham Business Complex<br>
    Browells Lane<br>
    Feltham<br>
    Middlesex<br>
    TW13 7LW
  </p>
  <p>
    <strong>Phone:&nbsp;</strong><a class="notlink grey" href="tel:0208 890 1600">0208 890 1600</a><br>
    <strong>E-mail:&nbsp;</strong><a class="notlink grey" href="mailto:sales@royaleglazing.co.uk">sales@royaleglazing.co.uk</a>
  </p>
</div>
