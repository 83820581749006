import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-galleries',
  templateUrl: './galleries.component.html',
  styleUrls: ['./galleries.component.scss']
})
export class GalleriesComponent implements OnInit {
  public galleryType: string;
  public gallery;
  public sub = new Subscription();

  public galleries = [
    {
      galleryType: "Conservatories",
      count: 14
    },
    {
      galleryType: "Doors",
      count: 15
    },
    {
      galleryType: "Windows",
      count: 16
    },
    {
      galleryType: "FrostedGlass",
      count: 11,
      descriptions: [
        "Charcoal sticks",
        "Oak",
        "Digital",
        "Florielle",
        "Warwick",
        "Minster",
        "Mayflower",
        "Everglade",
        "Chantilly",
        "Pelerine",
        "Taffeta"
      ]
    }
  ]

  constructor(private router: Router) {
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd){
        const queryParamsIndex = this.router.url.indexOf('?');
        const baseUrl = queryParamsIndex === -1 ? this.router.url :
        this.router.url.slice(0, queryParamsIndex);

        if(baseUrl.includes("/Galleries")){
          this.galleryType = baseUrl.replace("/Galleries/", "");
          this.gallery = this.galleries.find( g => g.galleryType === this.galleryType);
        }
      }
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void{
    this.sub.unsubscribe();
  }

  counter(i: number) {
    return new Array(i);
  }
}
