<h1 class="entry-title">Welcome to Royale Glazing Ltd</h1>
<div class="entry-content">
  <p>Royale Glazing Ltd is an established Double Glazing company in West london which specialises in windows &amp; Doors. We have developed and advanced over the years to provide a reliable, professional, and friendly service.</p>
  <p>Our range of products include Upvc &amp; Aluminium Front &amp; Back Doors, Composite Doors. Double Doors, Bi Folding Doors, and Patio Doors, Vertical Sliding Sash Windows, Porches etc.</p>
  <ul>
    <li>Free estimates</li>
    <li>10 years insurance backed guarantee</li>
    <li>High security locking systems</li>
    <li>Competitive prices</li>
    <li>Fensa&nbsp;registered company</li>
    <li>Energy rated windows as standard</li>
  </ul>
</div>
