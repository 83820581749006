import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DoorsPatiosComponent } from './pages/doors-patios/doors-patios.component';
import { WindowsComponent } from './pages/windows/windows.component';
import { ConservatoriesComponent } from './pages/conservatories/conservatories.component';
import { GalleriesComponent } from './pages/galleries/galleries.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'Windows', component: WindowsComponent },
  { path: 'DoorsPatios', component: DoorsPatiosComponent },
  { path: 'Conservatories', component: ConservatoriesComponent },
  { path: 'Galleries/:gallery', component: GalleriesComponent },
  { path: 'Testimonials', component: TestimonialsComponent },
  { path: 'ContactUs', component: ContactUsComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
