import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { WindowsComponent } from './pages/windows/windows.component';
import { DoorsPatiosComponent } from './pages/doors-patios/doors-patios.component';
import { ConservatoriesComponent } from './pages/conservatories/conservatories.component';
import { GalleriesComponent } from './pages/galleries/galleries.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { ContactUsComponent } from './pages/contact-us/contact-us.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    WindowsComponent,
    DoorsPatiosComponent,
    ConservatoriesComponent,
    GalleriesComponent,
    TestimonialsComponent,
    ContactUsComponent
  ],
  imports: [
    BrowserModule,
    MatSidenavModule,
    MatIconModule,
    AppRoutingModule,
    BrowserAnimationsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
