<h1 class="entry-title">Windows</h1>
<div class="entry-content">
  <p>
    Enjoy your home in a better light, by replacing your existing windows with
    PVCu in a wide selection of designs, colours, and styles. Windows
    themselves become an attractive feature of your home. It also provides the
    benefits of adding style, character, and value to your property. Most
    significantly, PVCu windows can enhance the security within your property,
    giving you peace of mind to enjoy your home in warmth.
  </p>
  <p><strong>BENEFITS OF PVCu WINDOWS</strong></p>
  <p>
    Available in white and other colour finishes.<br>
    Significantly reduce heat loss &amp; insulate against noise.<br>
    Improve the appearance of your home.<br>
    Provides for secure hinges and locking gear systems.<br>
    Adhere to high standards and testing.
  </p>
  <a routerLink="/Galleries/Windows">
    <img src="assets/images/windows.png" alt="" class="linkImage">
  </a>
</div>
